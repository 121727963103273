.codemirror-editor-container {
    min-width: 500px;
    position: relative;
}

.CodeMirror {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
}

@media only screen and (max-width: 1050px) {
    .CodeMirror{
        min-height: 50vh;
    }
}

#js_editor.show-js-hints .js-hint:not(.js-read-only) {
    background: yellow;
}

.js-error {
    background: pink !important;
}

.js-read-only {
    opacity: 0.6;
    font-style: italic;
}

.js-code-fold-link {
    text-decoration: underline;
    cursor: pointer;
}

#p5_canvas{
    /*margin-top: 200px;*/
}

iframe#preview {
    position: absolute;
    top: 0;
    right: 0;
    /*bottom: 0;*/
    left: 0;
    width: 100%;
    /*height: 100%;*/
    height: 550px;
}

#code_editor {
    border: 2px solid yellow;
}

.mdl-cell--5-col {
    border: 2px solid green;
}
